exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-archive-js": () => import("./../../../src/pages/archive.js" /* webpackChunkName: "component---src-pages-archive-js" */),
  "component---src-pages-getter-js": () => import("./../../../src/pages/getter.js" /* webpackChunkName: "component---src-pages-getter-js" */),
  "component---src-pages-index-jsx": () => import("./../../../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-stats-js": () => import("./../../../src/pages/stats.js" /* webpackChunkName: "component---src-pages-stats-js" */),
  "component---src-templates-album-js": () => import("./../../../src/templates/album.js" /* webpackChunkName: "component---src-templates-album-js" */),
  "component---src-templates-album-redirect-js": () => import("./../../../src/templates/album-redirect.js" /* webpackChunkName: "component---src-templates-album-redirect-js" */),
  "component---src-templates-decades-js": () => import("./../../../src/templates/decades.js" /* webpackChunkName: "component---src-templates-decades-js" */),
  "component---src-templates-hosts-js": () => import("./../../../src/templates/hosts.js" /* webpackChunkName: "component---src-templates-hosts-js" */),
  "component---src-templates-years-js": () => import("./../../../src/templates/years.js" /* webpackChunkName: "component---src-templates-years-js" */)
}

